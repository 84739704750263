<template>
    <v-card flat class="cardBorderStyle d-flex align-center justify-center">
      <div id="chart-container"></div>
    </v-card>
  </template>
  
  <script>
  import * as d3 from 'd3';
  import moment from 'moment';
  import { mapGetters } from 'vuex';
  export default {
    name: 'fiscalBarChart',
    props: {
      userSessionList: Array,
    },
    computed: {
      ...mapGetters(['kioskAnalytics', 'kioskAnalyticsDimension', 'windowSize'])
    },
    watch: {
      kioskAnalytics(newVal) {
        this.createBarChart();
      },
      windowSize(newVal) {
        this.createBarChart();
      }
    },
    data() {
      return {
        data: [
          { category: 'July', value: 0 },
          { category: 'Aug', value: 0 },
          { category: 'Sept', value: 0 },
          { category: 'Oct', value: 0 },
          { category: 'Nov', value: 0 },
          { category: 'Dec', value: 0 },
          { category: 'Jan', value: 0 },
          { category: 'Feb', value: 0 },
          { category: 'March', value: 0 },
          { category: 'April', value: 0 },
          { category: 'May', value: 0 },
          { category: 'June', value: 0 }
        ],
      };
    },
    mounted() {
      //this.createBarChart();
    },
    methods: {
      createBarChart() {
        d3.select('#chart-container').selectAll('*').remove();
        const width = this.kioskAnalyticsDimension.reportBarChartDimension.width;
        const height = this.kioskAnalyticsDimension.reportBarChartDimension.height;
        const margin = { top: 20, right: 20, bottom: 20, left: 30 };
        this.userSessionList.forEach(session => {
          let month = moment(session.userSessionDate).format('MM')
          switch (month) {
            case '01':
              var janVal = this.data.find(item => item.category === 'Jan')
              janVal.value = janVal.value + 1
              break;
            case '02':
              var febVal = this.data.find(item => item.category === 'Feb')
              febVal.value = febVal.value + 1
              break;
            case '03':
              var marchVal = this.data.find(item => item.category === 'March')
              marchVal.value = marchVal.value + 1
              break;
            case '04':
              var aprilVal = this.data.find(item => item.category === 'April')
              aprilVal.value = aprilVal.value + 1
              break;
            case '05':
              var mayVal = this.data.find(item => item.category === 'May')
              mayVal.value = mayVal.value + 1
              break;
            case '06':
              var juneVal = this.data.find(item => item.category === 'June')
              juneVal.value = juneVal.value + 1
              break;
            case '07':
              var julyVal = this.data.find(item => item.category === 'July')
              julyVal.value = julyVal.value + 1
              break;
            case '08':
              var augVal = this.data.find(item => item.category === 'Aug')
              augVal.value = augVal.value + 1
              break;
            case '09':
              var septVal = this.data.find(item => item.category === 'Sept')
              septVal.value = septVal.value + 1
              break;
            case '10':
              var octVal = this.data.find(item => item.category === 'Oct')
              octVal.value = octVal.value + 1
              break;
            case '11':
              var novVal = this.data.find(item => item.category === 'Nov')
              novVal.value = novVal.value + 1
              break;
            case '12':
              var decVal = this.data.find(item => item.category === 'Dec')
              decVal.value = decVal.value + 1
              break;
          }
        })
        const svg = d3.select('#chart-container')
          .append('svg')
          .attr('width', width)
          .attr('height', height);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        console.log('Sliced Categories current month: ', currentMonth)
        const startingMonthLastYear = (currentMonth + 12 - 12) % 12;
        console.log('Sliced Categories start month: ', startingMonthLastYear)
        const categories = this.data.map(d => d.category);
        //const slicedCategories = categories.slice(startingMonthLastYear).concat(categories.slice(0, currentMonth + 1));
        console.log('Categories in Annual Bar chart: ', categories)
  
        const xScale = d3.scaleBand()
          .domain(categories)
          .range([margin.left, width - margin.right])
          .padding(0.5);
  
        const yScale = d3.scaleLinear()
          .domain([0, d3.max(this.data, d => d.value)])
          .nice()
          .range([height - margin.bottom, margin.top]);
  
        svg.selectAll('rect')
          .data(this.data)
          .enter()
          .append('rect')
          .attr('x', d => xScale(d.category))
          .attr('y', d => yScale(d.value))
          .attr('width', xScale.bandwidth())
          .attr('height', d => height - margin.bottom - yScale(d.value))
          .attr('fill', 'steelblue');
  
        svg.selectAll('.bar-label')
          .data(this.data)
          .enter()
          .append('text')
          .text(d => (d.value !== 0 ? d.value : ''))
          .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
          .attr('y', d => yScale(d.value) - 5) // Adjust the vertical position as needed
          .attr('text-anchor', 'middle')
          .attr('class', 'bar-label');
  
        svg.append('g')
          .attr('class', 'x-axis')
          .attr('transform', `translate(0,${height - margin.bottom})`)
          .call(d3.axisBottom(xScale));
  
        svg.append('g')
          .attr('class', 'y-axis')
          .attr('transform', `translate(${margin.left},0)`)
          .call(d3.axisLeft(yScale));
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles for the chart */
  </style>